<template>
    <div class="loading-overlay">
        <div class="wrapper">
            <ClientOnly>
                <Vue3Lottie
                    :animationData="LoadingIcon"
                    :width="200"
                    :height="200"
                />
            </ClientOnly>
            <p class="fw-bold text-center m-3" v-html="loadingMessage"></p>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';

import LoadingIcon from '../assets/loading-icon.json';

const props = defineProps({
    message: {
        type: String,
        required: false,
        default: null,
    },
});

const loadingMessage = computed(() => {
    return props.message ?? 'Loading&hellip;';
});

</script>

<style scoped lang="scss">
.loading-overlay {
    display: flex;
    background-color: rgba(1.0, 1.0, 1.0, 0.65);
    position: fixed;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1099;
    align-items: center;
    color: white;
    font-size: 24px;
    font-family: museo-sans,sans-serif;

    .wrapper {
        margin: 50px auto;

    }

    .lottie-animation-container {
        max-width: 200px;
    }

    p {
        font-size: 1.5rem;
    }
}

</style>
