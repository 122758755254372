<template>
    <LoadingOverlay v-if="isLoading" />

    <NuxtLoadingIndicator />

    <NuxtLayout>
        <Toasts />
        <NuxtPage />
    </NuxtLayout>
</template>

<script setup lang="ts">
const { progress, isLoading, start, finish, clear } = useLoadingIndicator();
</script>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(1rem);
}
</style>

